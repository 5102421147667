<template>
  <div id="map"></div>
</template>

<script>
import L from "leaflet";
import "leaflet-draw";
import "leaflet-spin";
import {eventBus} from "@/main.js";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-geosearch/dist/geosearch.css"

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css"

//This code is required to show the marker icon on the geo-search event
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "Map",
  props: ["invalidateSize"],
  data() {
    return {
      map: null,
      tileLayer: null,
      mapConfiguration: {
        center: [0, 20],
        zoom: 16,
        minZoom: 0,
        maxZoom: 16,
        startZoom: 14,
        startZoomLocationBlocked: 4
      },
      scaler: null,
      tileLayerGrey: null,
      tileLayerStreet: null,
      tileLayerSatellite: null,
      searchControl: null,
      provider: null,
      drawControl: null,
      editableLayers: null,
      editableLayersCount: 0,
      geoJSONLayer: null,
      aoiItemGeoJSON: null,
    };
  },
  computed: {
    uploadedAOI() {
      return this.$store.getters.getUploadedAOI;
    },
    selectedCard() {
      let item;
      item = this.$store.getters.getAOIActiveItem;
      return item;
    },
    orderList() {
      return this.$store.getters.getOrderList;
    }
  },
  mounted() {
    this.initMap();
    this.initEventBusHandlers();
    this.initSearchControl
  },
  methods: {
    /**
     * This function initializes the leaflte map
     */
    initMap: function () {
      // Gray map
      const mapURLGray = process.env.VUE_APP_LEAFLET_BASE_MAP_GRAY;
      this.tileLayerGrey = L.tileLayer.wms(mapURLGray, {
        attribution: "&copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
      });

      // OSM map
      const mapURLOsm = process.env.VUE_APP_LEAFLET_BASE_MAP_OSM;
      this.tileLayerStreet = L.tileLayer(mapURLOsm, {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      });

      // Satellite map
      const mapURLSatellite = process.env.VUE_APP_LEAFLET_BASE_MAP_SATELLITE;
      this.tileLayerSatellite = L.tileLayer(mapURLSatellite, {
        attribution:
          "&copy;  Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ",
      });

      // Initialize maps
      var mapOptions = {
        minZoom: this.mapConfiguration.minZoom,
        maxZoom: this.mapConfiguration.maxZoom,
        layers: [this.tileLayerStreet],
      };

      this.map = L.map("map", mapOptions).setView([0, 0], 0);

      var ref = this;
      this.map.on('baselayerchange', function(e) {
        if (e.name === 'Light Gray Map') {
          ref.map.options.maxZoom = 16;
        } else {
          ref.map.options.maxZoom =  18;
        }
      });
  
      // Base maps
      var baseMaps = {
        "Open Street Map": this.tileLayerStreet,
        "Satellite Map": this.tileLayerSatellite,
			};
      
      // Initialize control to switch maps
			L.control.layers(baseMaps).addTo(this.map);

      // Initialize draw control and pass it to the FeatureGroup
      var drawnItems = new L.FeatureGroup();
      this.map.addLayer(drawnItems);

      // Initialize the FeatureGroup to store editable layers
      this.editableLayers = new L.FeatureGroup();
      this.map.addLayer(this.editableLayers);
    
      // Event handler for map interaction (create)
      this.map.on(L.Draw.Event.CREATED, event => {
        var layer = event.layer;
        this.removeEidtableLayer();
        this.editableLayers.addLayer(layer);
        this.$store.commit("setDrawnAOIs", this.editableLayers);

        let aoiArea = L.GeometryUtil.geodesicArea(this.$store.getters.getDrawnAOI.getLayers()[0].getLatLngs()[0]); // area in m²
        this.$store.commit("setAOIArea", aoiArea);
      });

      // Event handler for map interaction (edit done)
      this.map.on(L.Draw.Event.EDITED, () => {
        this.$store.commit("setDrawnAOIs", this.editableLayers);

        let aoiArea = L.GeometryUtil.geodesicArea(this.$store.getters.getDrawnAOI.getLayers()[0].getLatLngs()[0]); // area in m²
        this.$store.commit("setAOIArea", aoiArea);
      });

      // Event handler for map interaction (draw start)
      this.map.on(L.Draw.Event.DRAWSTART, () => {
        //this.$store.commit('setAOIsActiveItem', null);
      });

      // Event handler for map interaction (delete start)
      this.map.on(L.Draw.Event.DELETESTART, () => {
        //this.$store.commit('setAOIsActiveItem', null);
      });

      // Event handler for map interaction (edit start)
      this.map.on(L.Draw.Event.EDITSTART, () => {
        //this.$store.commit('setAOIsActiveItem', null);
      });

      // Event handler for map interaction (delete)
      this.map.on(window.L.Draw.Event.DELETED, () => {
        this.$store.commit("setDrawnAOIs", null);
        this.$store.commit("setAOIArea", null);
      });

      // Add scaler to map
      this.scaler = L.control.scale();
      this.scaler.addTo(this.map);

      // Init the map view
      this.initMapView();

      // Add leaflet geo-search control
      this.initSearchControl();
    },
    /**
     * This functions calls the leaflet invalidateSize function
     */
    invalidateMapSize() {
      this.map.invalidateSize();
    },
    /**
     * This function creates the event handlers for the event bus
     */
    initEventBusHandlers() {
      var ref = this;

      eventBus.$on('add-draw-control', function () {
        ref.addDrawControl();      
      });

      eventBus.$on('remove-draw-control', function () {
        ref.removeDrawControl();  
      });

      eventBus.$on('add-geojson-layer', function (geoJSONFile) {
        ref.addGeoJSONLayer(geoJSONFile);
      });

      eventBus.$on('remove-geojson-layer', function () {
        ref.removeGeoJSONLayer();
      });

      eventBus.$on('clear-map', function () {
        ref.removeEidtableLayer();
        ref.removeGeoJSONLayer();
      });

      eventBus.$on('refresh-location-aoi-item', function () {
        ref.refreshLocationAoiItem();
      });
    },
    /**
     *Adds the search bar to the map
     */
    initSearchControl() {
      const provider = new OpenStreetMapProvider();
      this.searchControl = new GeoSearchControl({
        provider: provider,
        style: 'bar'
      });
      this.map.addControl(this.searchControl);
    },
    /**
     * Adds the draw control icons to the map
     */
    addDrawControl() {
      // Draw control options
      var drawControlOptions = {
        position: 'topleft',
        draw: {
          rectangle: {
            showArea: true,
            shapeOptions: {
              color: '#007bff',
              weight: 3  
            },
            metric: 'km'
          },
          polygon: {
            allowIntersection: false,
            showArea: true,
            drawError: {
              color: "#b00b00",
              timeout: 1000
            },
            shapeOptions: {
              color: "#007bff",
              weight: 3  
           },
           metric: 'km'
          },
          polyline: false,
          circle: false,
          marker: false,
          circlemarker: false,
        },
        edit: {
          featureGroup: this.editableLayers, // required
          remove: true
        }
      }
      
      this.drawControl = new L.Control.Draw(drawControlOptions);
      this.map.addControl(this.drawControl);
    },
    /**
     * Deletes the draw control icons from the map
     */
    removeDrawControl() {
      if (this.drawControl != null) {
        this.map.removeControl(this.drawControl);
      }
    },
    /**
     * This function removes all editable layers (drawn layers) from the map
     */
    removeEidtableLayer() {
      var ref = this;
      this.editableLayers.eachLayer(function(layer) {
        ref.editableLayers.removeLayer(layer);
      });
    },
    /**
     * Sets the view point to the user's geo location.
     */
    initMapView() {
      this.map.spin(true);

      var ref = this;
      this.map.locate({setView: true, maxZoom: ref.mapConfiguration.startZoom}) 
      .on('locationfound', function(){
        ref.map.spin(false);
      })
      .on('locationerror', function(){
          ref.map.setView(ref.mapConfiguration.center, ref.mapConfiguration.startZoomLocationBlocked);
          ref.map.spin(false);
      });

      //this.searchControl.clearResults();
    },
    /**
     * This function adds a GeoJSON layer to the map
     */
    addGeoJSONLayer(geoJSONFile) {
      this.removeGeoJSONLayer();
      this.geoJSONLayer = L.geoJSON(geoJSONFile).addTo(this.map);
      this.map.fitBounds(this.geoJSONLayer.getBounds());
    },
     /**
     * This function removes a GeoJSON layer from the map
     */
    removeGeoJSONLayer() {
      if (this.geoJSONLayer) {
        this.map.removeLayer(this.geoJSONLayer);
      }
    },
    /**
     * This function adds a GeoJSON layer stored in the AOI item to the map
     */
    addAoiItemGeoJSON() {
      // Remove the current aoi
      this.removeAoiIemGeoJSON();

      // Styling and options
      var style = {
        color: "#007bff",
        weight: 3,
        fill: true
      };
      var options = {
        interactive: false
      };

      // Find item in orders list
      let searchID = this.selectedCard.substring(3);
      let aoiItem = this.orderList.find(x => x.order_id  === searchID);

      // Create the leaflet GeoJSON layer
      this.aoiItemGeoJSON = new L.geoJSON(aoiItem.geojson, {
        options: options,
        style: style
      });

      // Add the layer to the map
      this.$nextTick(() => {
        this.map.addLayer(this.aoiItemGeoJSON);
        this.map.fitBounds(this.aoiItemGeoJSON.getBounds());
      });
    },
    /**
     * This function removes the GeoJSON layer from the mao
     */
    removeAoiIemGeoJSON() {
      // Remove the current aoi
      if (this.aoiItemGeoJSON != null) {
        this.map.removeLayer(this.aoiItemGeoJSON);
      }
    },
    /**
     * Refreshes the location of the AOI item
     */
    refreshLocationAoiItem() {
      if (this.aoiItemGeoJSON != null) {
        this.map.fitBounds(this.aoiItemGeoJSON.getBounds());
      }
    }
  },
  watch: {
    /**
     * This watch function listens to the toogle event of the parent component (sidebar). The events is  passed to the component
     * by the `invalidateSize` prop
     */
    invalidateSize: function () {
      this.invalidateMapSize();
    },
    /**
     * This function is triggered when the aoi upload state changed 
     */
    uploadedAOI(v) {
      if (v == null) {
        this.removeGeoJSONLayer();
      } else {
        this.addGeoJSONLayer(v);
        let aoiArea = L.GeometryUtil.geodesicArea(this.geoJSONLayer.getLayers()[0].getLatLngs()[0]); // area in m²
        this.$store.commit("setAOIArea", aoiArea);
      }
    },
    /**
     * This function is executed when the aoi card item has changed
     */
    selectedCard(id) {
      if (id == null) {
        this.removeAoiIemGeoJSON();
      } else {
        this.addAoiItemGeoJSON(id);
        this.$store.commit("setCreateStep", "step_1");
        //this.addAOIBoundaryLayer();
        //this.removeEidtableLayer();
      }
    },
  },
};
</script>

<style scoped>
#map {
  width: auto;
  height: 100%;
}
</style>

