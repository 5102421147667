<!-- 
Note: In this version the update functionality is disabled. Therefore, some code is not required any longer. In order 
      to add the funtionality later on, the obsolete code visible.   
 -->
<template>
  <div id="profile-container" class="container">
    <ChangePasswordModal />
    <b-overlay :show="showOverlay" spinner-variant="secondary" rounded="sm">
      <div>
        <b-card title="Personal Information">
          <p class="text-muted card-info-text">
            This form shows your personal user data. (<span class="required"></span> Required fields)
          </p>
          <b-alert v-if="profileLoadingError" show variant="danger"><strong>Error!&nbsp;</strong>Profile information could not be loaded</b-alert>
          <b-form>
            <!-- Mail information -->
            <b-form-group>
              <label class="label-style">E-mail address</label>
              <label class="required"></label>
              <b-form-input v-model="formData.mail" disabled></b-form-input>
            </b-form-group>

            <!-- Name information -->
            <div class="row">
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">First Name</label>
                  <label class="required"></label>
                  <b-form-input
                    v-model="formData.firstName"
                    placeholder="First name ..."
                    autocomplete="given-name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">Last Name</label>
                  <label class="required"></label>
                  <b-form-input
                    v-model="formData.lastName"
                    placeholder="Last name ..."
                    autocomplete="family-name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Organization information -->
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">Organization Name</label>
                  <label class="required"></label>
                  <b-form-input
                    v-model="formData.organizationName"
                    placeholder="Organization name ..."
                    autocomplete="organization"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Organization information -->
            <b-form-group>
              <label class="label-style">Organization Type</label>
              <label class="required"></label>
              <!-- <b-form-select
                v-model="formData.organizationType"
                :options="companyTypeOptions"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    Organization type ...
                  </b-form-select-option>
                </template>
              </b-form-select> -->
              <b-form-input
                v-model="formData.organizationType"
                placeholder="Organization type ..."
                disabled
              ></b-form-input>
            </b-form-group>

            <!-- Address information 1 -->
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">Country</label>
                  <!-- <b-form-select
                    v-model="formData.country"
                    :options="countryOptions"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Country ...</b-form-select-option
                      >
                    </template>
                  </b-form-select> -->
                  <b-form-input
                    v-model="formData.country"
                    placeholder="Country ..."
                    autocomplete="country"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Address information -->
            <div class="row">
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">Postal Code</label>
                  <b-form-input
                    v-model="formData.postalCode"
                    placeholder="Postal code ..."
                    autocomplete="postal-code"
                    disabled
                  >
                    ></b-form-input
                  >
                </b-form-group>
              </div>
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">City</label>
                  <b-form-input
                    v-model="formData.city"
                    placeholder="City ..."
                    autocomplete="address-level2"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Address information -->
            <b-form-group>
              <label class="label-style">Street</label>
              <b-form-input
                v-model="formData.street"
                placeholder="Street ..."
                autocomplete="street-address"
                disabled
              ></b-form-input>
            </b-form-group>

            <!-- <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">Password</label><br />
                  <b-link v-on:click="changePassword">Change password</b-link>
                </b-form-group>
              </div>
            </div> -->

            <div class="row mt-4">
              <div class="col text-right">
                <b-button variant="primary mr-2" @click="refreshBtnClicked()"
                  >Refresh</b-button
                >
                <!-- <b-button variant="primary" @click="submitBtnClicked()" :disabled="updateBtnDisabled"
                  >Update data</b-button
                > -->
              </div>
            </div>

          </b-form>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ChangePasswordModal from "@/pages/User/ChangePasswordModal.vue";
import { eventBus } from "@/main.js";
import axios from "axios";
import axios_services from "@/axios/axios-services.js";

export default {
  name: "ProfilePage",
  components: {
    ChangePasswordModal,
  },
  computed: {
    showOverlay() {
      return (this.loadUtilsData || this.loadProfileData);
    },
    updateBtnDisabled() {
      return (
        (
          (this.formData.firstName.length == 0) ||
          (this.formData.lastName == 0) ||
          (this.formData.organizationName == 0) ||
          (this.formData.organizationType == null)
        ) ||
        (
          (this.dataChanged == false)
        )
      );
    }
  },
  mounted() {
    this.loadUserData();
    this.loadCountriesAndCompanyTypes();
  },
  data() {
    return {
      formData: {
        mail: "",
        firstName: "",
        lastName: "",
        organizationName: "",
        organizationType: "",
        country: "",
        postalCode: "",
        city: "",
        street: "",
      },
      companyTypeOptions: [],
      countryOptions: [],

      loadUtilsData: false,
      loadProfileData: false,
      dataChanged: false,

      profileLoadingError: false
    };
  },
  methods: {
    changePassword() {
      eventBus.$emit("show-modal-change-password");
    },
    refreshBtnClicked() {
      this.loadUserData();
    },
    loadUserData() {
      this.loadProfileData = true;

      axios_services
        .get("user/")
        .then((response) => {
          this.initProfileForm(response.data);
          this.loadProfileData = false;
          this.profileLoadingError = false;
        })
        .catch((error) => {
          console.log("Error: Cannot load user data. Details: ", error.response.data);
          this.loadProfileData = false;
          this.profileLoadingError = true;
        })
        .finally( () => {
          this.dataChanged = false;
        });
    },
    loadCountriesAndCompanyTypes() {
      this.loadUtilsData = true;

      const reqContries = axios_services.get(`/utils/countries`);
      const reqCompanies = axios_services.get(`/utils/organisation_types`);

      axios
        .all([reqContries, reqCompanies])
        .then(
          axios.spread((...responses) => {
            this.countryOptions = responses[0].data.countries;
            this.companyTypeOptions = responses[1].data.organisation_types;
            this.loadUtilsData = false;
          })
        )
        .catch((errors) => {
          console.log("Error:", errors);
          this.loadUtilsData = false;
        });
    },
    initProfileForm(profileData) {
      this.formData.mail = profileData.email;
      this.formData.firstName = profileData.first_name;
      this.formData.lastName = profileData.last_name;
      this.formData.organizationName = profileData.organisation_name;
      this.formData.organizationType = profileData.organisation_type;
      this.formData.country = profileData.country;
      this.formData.postalCode = profileData.postal_code;
      this.formData.city = profileData.city;
      this.formData.street = profileData.street_name;
    },
    submitBtnClicked() {
      this.showNotification("success", "Success! Data updated", "Personal data successfully updated");
      this.showNotification("danger", "Error!", "Personal data could not be updated");
    },
    showNotification(variant, title, content, hideDelay = 4000) {
      this.$parent.$bvToast.toast(content, {
        autoHideDelay: hideDelay,
        title: title,
        variant: variant,
        solid: true
      });
    },
  },
  watch: {
    formData: {
      handler() {
        this.dataChanged = true;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
#profile-container {
  margin-top: 50px;
}

.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 0px;
}

.required {
  margin-bottom: 0px !important;
}

.required:before {
  padding-left: 2px;
  content: "*";
  color: red;
  margin-bottom: 0px !important;
}
</style>
