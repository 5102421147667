<template>
  <div id="result-container" class="container">
    <b-overlay :show="orderLoadingStatus==true" rounded="sm" spinner-variant="secondary">
    <b-card>
      <b-card-title>
        <div class="row">
          <div class="col">Orders</div>
          <div class="col text-right">
            <b-button variant="outline-secondary" size="sm" @click="refreshBtnClicked()"><b-icon class="mr-0" icon="arrow-repeat"></b-icon></b-button>
          </div>
        </div>
      </b-card-title>

      <!-- <p class="text-muted card-info-text">List of all orders</p> -->
      <div class="mb-4"></div>

      <div class="row">
        <div class="col">
          <b-input-group size="">
            <b-input-group-append is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-append>
            <b-form-input v-model="orderSearchPattern" type="search" placeholder="Filter by AOI name ..." autocomplete="off"></b-form-input>
          </b-input-group>
          <div class="text-right mb-4 mt-2">
            <b-link @click="clearFilter()">Clear filter</b-link>
          </div>
        </div>
      </div>
      
      <b-table
        class="bg-color mt-2 mb-3"
        thead-class="hidden-header"
        hover
        bordered
        :fields="fields"
        :items="orderList"
        :per-page="perPage" 
        :current-page="currentPage"
      >
        <!-- AOI name-->
        <template v-slot:cell(aoi_name)="data">
          {{data.item.aoi_name}}
          <span v-if="data.item.aoi_description.length > 0">
            <b-icon :id="'popover-description-' + data.item.order_id" icon="info-circle" variant="info" shift-v="0" shift-h="2"></b-icon>
            <b-popover :target="'popover-description-' + data.item.order_id" triggers="hover" placement="top">
              <template #title>Description</template>
              {{data.item.aoi_description}}
            </b-popover>
          </span>
        </template>

        <!-- State-->
        <template v-slot:cell(processing_state)="data">
          <div class="text-center">
            <b-badge class="state-badge" :variant='getStateVariantText(data.item.processing_state).variant'>{{getStateVariantText(data.item.processing_state).text}}</b-badge>
          </div>
        </template>

        <!-- Result-->
        <template v-slot:cell(result_link)="data">
          <div class="text-center">
            <b-button v-if="data.item.processing_state != 'failed'" size="sm" :disabled="data.item.result_link==null" @click="downloadResult(data.item)">
              <b-spinner v-if="showDownloadSpinnerById===data.item.order_id" small></b-spinner>
              <b-icon v-else icon="download" class="mr-0" size="0.9"></b-icon>
            </b-button>
            <span v-else>
              <b-link :id="'popover-error-' + data.item.order_id">Details</b-link>
            </span>
            <b-popover v-if="data.item.processing_state == 'failed'" :target="'popover-error-' + data.item.order_id" triggers="focus" placement="top" variant="danger">
              <template #title>Error</template>
              <div v-if="data.item.error_message != null">
                {{data.item.error_message}}
              </div>
              <div v-else>
                Error has not been announced
              </div>
            </b-popover>
          </div>
        </template>
      </b-table>

      <i v-if="orderList.length == 0" class="ml-1">No items could be found!</i>

      <div v-if="orderList.length > 0"> 
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table">
      </b-pagination>
      </div>

    </b-card>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "OrderResults",
  components: {
  },
  mounted() {
    this.loadOrders();
  },
  computed: {
    orderList() {
      let tmpOrderList = this.$store.getters.getOrderList.slice();
      var filteredOrders = tmpOrderList.filter((order) => {
        if (order.aoi_name.toLowerCase().startsWith(this.orderSearchPattern.toLowerCase())) {
          return order;
        }
      });
      return filteredOrders;
    },
    orderLoadingStatus() {
      return this.$store.getters.getOrderLoadingStatus;
    },
    orderLoadingError() {
      return this.$store.getters.getOrderLoadingError;
    },
    rows() {
      return this.orderList.length;
    },
  },
  data() {
    return {
      orderSearchPattern: '',
      fields: [
        { key: 'aoi_name', label: 'AOI Name', class: 'align-middle' },
        { key: 'start_date', label: 'Begin', class: 'align-middle' },
        { key: 'end_date', label: 'End', class: 'align-middle' },
        { key: 'processing_state', label: 'State', class: 'align-middle text-center' },
        { key: 'result_link', label: 'Download', class: 'align-middle text-center' },
      ],
      currentPage: 1,
      perPage: 10,
      showDownloadAnimtion: false,
      showDownloadSpinner: false,
      showDownloadSpinnerById: null
    };
  },
  methods: {
    getStateVariantText(state) {
      if (state === 'queued') {
        return {variant: "secondary", text: "Queued"}
      } else if (state === 'inProgress') {
        return {variant: "primary", text: "In Progress"}
      } else if (state === 'finished') { 
          return {variant: "success", text: "Finished"}
      } else if (state === 'failed') {
          return {variant: "danger", text: "Failed"}
      }
    },
    loadOrders() {
      this.$store.dispatch('fetchOrders').then(() => {});
    },
    clearFilter() {
      this.orderSearchPattern = "";
    },
    refreshBtnClicked() {
      this.clearFilter();
      this.loadOrders();
    },
    downloadResult(item) {
      this.showDownloadSpinnerById = item.order_id;
      var ref = this;
      setTimeout(() => {
        // Note: The timeout is a hacky solution an could probably be improved
        ref.showDownloadSpinnerById = null
      }, 1000);
      window.location.href = item.result_link.concat("/download");   
    }
  },
};
</script>

<style scoped>
#result-container {
  margin-top: 50px;
}
.state-badge {
  width: 85px;
}
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
</style>
