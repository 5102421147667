<template>
  <div>
    <b-form-group>
      <label class="label-style">AOI Name</label>
      <b-icon id="info-icon-aoi-name" icon="info-circle" variant="info" shift-v="0" shift-h="3" scale="0.9"></b-icon>
      <b-popover target="info-icon-aoi-name" triggers="hover" placement="right">
         Please provide a unique meaningful AOI name.
      </b-popover>
      <b-input 
        placeholder="AOI name ..." 
        autocomplete="off" 
        v-model="aoiName"
        @focus="aoiCreateInputFocused"
        maxlength="40"
      ></b-input>
    </b-form-group>
    <b-form-group>
      <label class="label-style">AOI Description</label>
        <b-form-textarea
        rows="2"
        no-resize
        placeholder="AOI description ..."
        v-model="aoiDescription"
        autocomplete="off"
        @focus="aoiCreateInputFocused"
        maxlength="80"
      ></b-form-textarea>
    </b-form-group>
    <b-button block variant="primary" @click="onSubmit" :disabled="submitBtnDisabled">Next step</b-button>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";

export default {
  name: "CreateOrderStep1",
  components: {},
  computed: {
    submitBtnDisabled() {
      return (this.aoiName.length == 0);
    }
  },
  mounted() {
    eventBus.$emit('clear-map');
    this.$store.commit("setAOIArea", null);
  },
  data() {
    return {
      aoiName: "",
      aoiDescription: ""
    };
  },
  methods: {
    onSubmit() {
      this.$store.commit("setCreateStep", "step_2");
      let payloadStepOne = {
        aoiName: this.aoiName,
        aoiDescription: this.aoiDescription
      };
      this.$store.commit("setOrderStepOne", payloadStepOne);
    },
    aoiCreateInputFocused() {
      this.$store.commit('setAOIActiveItem',null);
    },
  },
};
</script>

<style scoped>
  .label-style {
    font-size: 12px;
    text-transform: uppercase;
    color: #9a9a9a;
    margin-bottom: 4px;
  }
  #info-icon-aoi-name {
    color: #9a9a9a;
  }
</style>

