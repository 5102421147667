<template>
  <div>

      <div>
          <b-alert v-model="showDateRangeWarning" show dismissible variant="warning" @dismissed="showDateRangeWarning=false">
            <div class="alert-heading">{{warningTxt}}</div>
          </b-alert>

          <b-form-group>
            <label class="label-style">Start Date</label>
            <b-icon id="info-icon-begin" icon="info-circle" scale="0.8" shift-h="2" variant="info"></b-icon>
            <b-popover target="info-icon-begin" triggers="hover" placement="top">
              Please select the period’s start date for which the Wetland pre-Inventory shall be processed.
            </b-popover>
            <b-form-datepicker v-model="begin" reset-button min="2016-01-01"></b-form-datepicker>
          </b-form-group>
          <b-form-group>
            <label class="label-style">End Date</label>
            <b-icon id="info-icon-end" icon="info-circle" scale="0.8" shift-h="2" variant="info"></b-icon>
            <b-popover target="info-icon-end" triggers="hover" placement="top">
              Please provide the period's end date. Minimum: One year after the start date
            </b-popover>
            <b-form-datepicker v-model="end" reset-button min="2016-01-01"></b-form-datepicker>
          </b-form-group>
        </div>

        <b-button 
          block variant="primary" 
          @click="onSubmit" 
          :disabled="submitBtnDisabled || showWaitingAnimation"
        >
          <span v-if="!showWaitingAnimation">Save order</span>
          <b-spinner v-else small class="mr-2"></b-spinner>
        </b-button>
        <b-button block variant="secondary" @click="onCancel">Cancel</b-button>

  </div>
</template>   

<script>
import axios_services from "@/axios/axios-services.js";

export default {
  name: "CreateOrderStep3",
  components: {},
  computed: {
    aoiMode() {
      return this.$store.getters.getAoiMode;
    },
    submitBtnDisabled() {
      if ((this.begin == "") || (this.end == "")) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      begin: "",
      end: "",
      warningTxt: "",
      showDateRangeWarning: false,
      showWaitingAnimation: false
    }
  },
  methods: {
    async onSubmit() {
      let ordersInProgOrQeueud
      try {
        ordersInProgOrQeueud = await this.ordersInProgOrQeueud();
      } catch {
        return;
      }
      
      // Check if orders are already in progress or queued
      if (ordersInProgOrQeueud) {
        this.warningTxt = "Order can not be submitted. There is already one order in progress or queued.";
        this.showDateRangeWarning = true;
        return;
      } else {
        this.warningTxt = "";
        this.showDateRangeWarning = false;
      }      

      // Validate date values
      let dateValuesCorrect = this.validateDateValues(this.begin, this.end)
      if (!dateValuesCorrect) {
        return;
      }

      let stepThreePayload = {
        begin: this.begin,
        end: this.end,
      };

      this.$store.commit("setOrderStepThree", stepThreePayload);

      // Store order
      let orderDetails = this.$store.getters.getOrder;

      let aoiGeometry;
      if (this.aoiMode === 'MODE_UPLOAD') {
        aoiGeometry = orderDetails.geometry.features[0].geometry;
      } else if (this.aoiMode === 'MODE_DRAW') {
        aoiGeometry = orderDetails.geometry.getLayers()[0].toGeoJSON().geometry;
      }

      let orderPayload = {
        aoi_name: orderDetails.aoiName,
        aoi_description: orderDetails.aoiDescription,
        start_date: orderDetails.begin,
        end_date: orderDetails.end,
        geojson: aoiGeometry,
      };

      // Submit order
      this.showWaitingAnimation = true;
      axios_services
        .post("orders/", orderPayload)
        .then((response) => {
          this.showNotification("success", "Success!", "Order successfully extracted", "b-toaster-top-right");
    
          // Fetch product data
          this.$store.dispatch('fetchOrders').then(() => {
            let id = "id_".concat(response.data.order_id);
            this.$store.commit('setAOIActiveItem', id);
          }); 
        })
        .catch((err) => {
          console.log("Error: Cannot create order: ", err.response);
          this.showNotification("danger", "Error!", "Order could not be created", "b-toaster-top-right");
        })
        .then(() => {
          this.showWaitingAnimation = false;
          this.$store.commit("setCreateStep", "step_1");
          this.$store.commit("clearOrder");
        });
    },
    onCancel() {
      this.$store.commit("setCreateStep", "step_1");
      this.$store.commit("clearOrder");
    },
    validateDateValues(begin, end) {
      if (begin >= end) {
        console.log("Error: date range not correct");
        this.warningTxt = "The submitted date values are not correct.";
        this.showDateRangeWarning = true;
        return false;
      } 
      
      let diffTime = Math.abs(new Date(end) - new Date(begin));
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < process.env.VUE_APP_DIFF_DAYS_MIN) {
        console.log("Error: date range not correct");
        this.warningTxt = `Date range must be larger than one year. Please change the date values.`;
        this.showDateRangeWarning = true;
        return;
      }

      else {
        this.warningTxt = "";
        this.showDateRangeWarning = false;
        return true;
      }
    },
    async ordersInProgOrQeueud() {
      try {
          const resp = await axios_services.get('orders/user');
          const ordersInProgress = resp.data.orders.filter((obj) => obj.processing_state === 'inProgress').length;
          const ordersQueued = resp.data.orders.filter((obj) => obj.processing_state === 'queued').length;

          if (ordersInProgress >= 1 || ordersQueued >= 1) {
            return true;
          } else {
            return false;
          }
      } catch (err) {
        console.log("Error: ", err)
        throw err;
      }
    },
    showNotification(variant, title, content, toaster) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true,
        toaster: toaster
      });
    },
  },
};
</script>

<style scoped>
.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}
</style>
