<template>
  <div>
    <div class="row pb-2">
      <div class="col-sm my-auto">
        <span class="font-weight-bold">Create Order</span>
      </div>
      <div class="col-sm text-right">
        <b-button
          size="sm"
          @click="createOrderContainerOpened = !createOrderContainerOpened"
          variant="secondary"
          class="mb-0"
        >
          <b-icon :icon="createOrderToggleIcon"></b-icon>
        </b-button>
      </div>
    </div>

    <b-collapse v-model="createOrderContainerOpened">
      <b-overlay :show="createOrderOverlay==true" rounded="sm">
      <b-card>
        <div v-if="createStep=='step_1'">
          <CreateOrderStep1/>
        </div>
        <div v-else-if="createStep=='step_2'">
          <CreateOrderStep2/>
        </div>
        <div v-else-if="createStep=='step_3'">
          <CreateOrderStep3/>
        </div>
        <div style="text-align:center;margin-top:12px;">
          <span class="step" v-bind:class="{ active: this.createStepActiveArr[0]==true }"></span>
          <span class="step" v-bind:class="{ active: this.createStepActiveArr[1]==true }"></span>
          <span class="step" v-bind:class="{ active: this.createStepActiveArr[2]==true  }"></span>
        </div>
      </b-card>
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
import CreateOrderStep1 from "@/pages/CreateOrder/CreateOrderStep1.vue"
import CreateOrderStep2 from "@/pages/CreateOrder/CreateOrderStep2.vue"
import CreateOrderStep3 from "@/pages/CreateOrder/CreateOrderStep3.vue"
  
export default {
  name: "CreateOrder",
  components: {
    CreateOrderStep1,
    CreateOrderStep2,
    CreateOrderStep3
  },
  computed: {
    createStep() {
      return this.$store.getters.getCreateStep;
    },
    createOrderToggleIcon() {
      return this.createOrderContainerOpened ? "chevron-up" : "chevron-down";
    },
  },
  data() {
    return {
      createStepActiveArr: [true, false, false],
      createOrderContainerOpened: true,
      createOrderOverlay: false,
    };
  },
  mounted() {
    this.$store.commit("clearOrder");
    this.$store.commit("setCreateStep", "step_1");
  },
  methods: {
  },
  watch: {
    createStep(stepValue) {
      if (stepValue == 'step_1') {
        this.createStepActiveArr = [true, false, false];
      } else if (stepValue == 'step_2') {
        this.createStepActiveArr = [true, true, false];
      } else if (stepValue == 'step_3') { 
        this.createStepActiveArr = [true, true, true];
      }
    }
  }
};
</script>

<style scoped>
/* Make circles that indicate the steps of the form */
.step {
  height: 12px;
  width: 12px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step */
.step.active {
  opacity: 0.8;
  background-color: #007bff;
}

/* Mark the steps that are finished */
/* .step.finish {
  background-color: #007bff;
} */

.disabled-link {
  cursor: not-allowed;
  color: #6c757d;
}
</style>
