import Vue from 'vue'
import VueRouter from 'vue-router'

import CreateOrder from '@/pages/CreateOrder'
import OrderResults from '@/pages/OrderResults'
import AboutPage from '@/pages/User/About.vue'
import ProfilePage from '@/pages/User/Profile.vue'

Vue.use(VueRouter)

export const routes = [
  { name: 'orders'  , path: '/', component: CreateOrder },
  { name: 'results', path: '/results', component: OrderResults },
  { name: 'about', path: '/about', component: AboutPage },
  { name: 'profile', path: '/profile', component: ProfilePage },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
