<template>
  <div>
    <!-- Sidebar header -->
    <div id="sidebar-header" class="container-fluid">
      <div id="sidebar-header-font">Orders</div>
    </div>

    <!-- Sidebar content with scrollbar -->
    <div id="sidebar-content-outer" >
      <div id="sidebar-content-inner" class="container-fluid">
        <div>
          <CreateOrder/>

          <b-input-group size="sm" class="mb-2 mt-2">
            <b-input-group-append is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-append>
            <b-form-input v-model="orderSearchPattern" type="search" placeholder="Filter by order name ..." autocomplete="off"></b-form-input>
          </b-input-group>
          
          <div v-if="orderLoadingStatus == true">
            <div class="d-flex justify-content-center mt-3">
              <b-spinner variant="secondary" label=""></b-spinner>
            </div>
          </div>
          <div v-else>
           <div v-if="orderLoadingError ==  null">
             <div v-if="this.orderList.length > 0">
              <div v-for="(item, key) in this.orderList" :data="item" v-bind:key="key">
                <AOIItem :cardData="item" :cardID="'id_'+item.order_id"/>
              </div>
            </div>
            <div v-else>
              <i>No items could be found!</i>
            </div>
           </div>
           <div v-else>
             <b-alert show variant="danger">Error! Orders could not be loaded.</b-alert>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateOrder from "@/pages/CreateOrder/CreateOrder.vue";
import AOIItem from "@/pages/CreateOrder/AOIItem.vue"

export default {
  name: "SidebarContent",
  components: {
    CreateOrder,
    AOIItem
  },
  data() {
    return {
      orderSearchPattern: "",
    };
  },
  computed: {
    orderList() {
      let tmpOrderList = this.$store.getters.getOrderList.slice();
      var filteredOrders = tmpOrderList.filter((order) => {
        if (order.aoi_name.toLowerCase().startsWith(this.orderSearchPattern.toLowerCase())) {
          return order;
        }
      });
      return filteredOrders;
    },
    orderLoadingStatus() {
      return this.$store.getters.getOrderLoadingStatus;
    },
    orderLoadingError() {
      return this.$store.getters.getOrderLoadingError;
    }
  },
  mounted() {
    this.initSidebarHeight();
    this.loadAoiList();
  },
  created() {
    // Event handler for the windows resize event
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    /**
     * This function sets the height of the sidebar parent container
     */
    initSidebarHeight() {
      // Get the height of the sidebar and the sidebar header
      var sidebarHeight = document.getElementById("sidebar").offsetHeight;
      var sidebarHeaderHeight = document.getElementById("sidebar-header").offsetHeight;

      // Calculate new height and resize outer container
      var _height = sidebarHeight - sidebarHeaderHeight;
      var _height_str = String(_height) + "px";

      document.getElementById("sidebar-content-outer").style.height = _height_str;
    },
    /**
     * This function handles the window resize event
     */
    handleResize() {
      this.initSidebarHeight();
    },
    /**
     * Loads a list of all orders
     */
    loadAoiList() {
      // Fetch product data
      this.$store.dispatch('fetchOrders').then(() => {
        // console.log("orders loaded ...");
      }) 
    }
  },
};
</script>

<style scoped>
#sidebar-header {
  border-bottom: 1px solid lightgray;
  padding-top: 16px;
  padding-bottom: 16px;
}

#sidebar-header #sidebar-header-font {
  font-weight: bold;
  text-transform: uppercase;
}

#sidebar-content-outer {
  background-color: inherit;
}

#sidebar-content-inner {
  height: 100%;
  padding-top: 10px;
  overflow-y: scroll;
}
</style>
