import Vue from 'vue'
import App from './App.vue'
import store from "./store/store.js";

import "@/auth";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import {BootstrapVueIcons } from 'bootstrap-vue'
import router from '@/router/router.js'

Vue.config.productionTip = false

// Add Bootstrap dependencies
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(IconsPlugin)    // Optionally install the BootstrapVue icon components plugin

// Importing the global css file
import "@/assets/css/global.css"

// Create an event-bus
export const eventBus = new Vue();


// Keycloak authentication
Vue.$keycloak
  .init({
    onLoad: "login-required",
  })
  .success((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.log("Authentication successful");

      let payload = {
        accessToken: Vue.$keycloak.token,
        refreshToken: Vue.$keycloak.refreshToken,
      };

      // console.log("payload: ", payload);
      
      // Store the access and refresh token
      localStorage.setItem('accessToken', payload.accessToken);
      localStorage.setItem('refreshToken', payload.refreshToken)
            
      // Create the vue app
      new Vue({
        router,
        store,
        render: h => h(App),
      }).$mount('#app')

      // Token refresh
      setInterval(() => {
        Vue.$keycloak.updateToken(70).success((refreshed) => {
          if (refreshed) {
            // console.log('Token refreshed');
            
            let payload = {
              accessToken: Vue.$keycloak.token,
              refreshToken: Vue.$keycloak.refreshToken,
            };

            // Uodate the access and refresh token
            localStorage.setItem('accessToken', payload.accessToken);
            localStorage.setItem('refreshToken', payload.refreshToken)
          } else {
            //console.log('Token not refreshed, valid for '  + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          }
        }).error(()=>{
            console.log('Error: Failed to refresh token');
        });
      }, 1000);
    }
  })
  .error(() => {
    console.log("Authenticated failed");
  });


