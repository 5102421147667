<template>
  <div>
    <b-navbar id="top-nav-bar" fixed="top" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand @click="reloadPage()" href="#">
        <img id="navbar-img" src="./../assets/gwa-logo.png" alt="GLobWetland">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
           <b-nav-item>
            <router-link exact class="nav-link" :to="{ name: 'orders'}">Orders</router-link>
          </b-nav-item>
          <b-nav-item >
            <router-link exact class="nav-link" :to="{ name: 'results'}">Results</router-link>
          </b-nav-item>
          <b-nav-item @click="openTutorial()">
            <div>Tutorial</div>
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content>
              <em><b-icon icon="person-fill"></b-icon></em>
            </template>
            <b-dropdown-item class="" to="/profile"><b-icon icon="person" shift-h="-2" scale="0.9"></b-icon><span>&nbsp;&nbsp;Profile</span></b-dropdown-item>
            <b-dropdown-item class="" to="/about"><b-icon icon="info-circle " shift-h="-2" scale="0.9"></b-icon><span>&nbsp;&nbsp;About</span></b-dropdown-item> 
            <b-dropdown-item href="#" @click="signout"><b-icon icon="box-arrow-in-right" shift-h="-3" scale="0.9"></b-icon>&nbsp;&nbsp;Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template> 

<script>
import Vue from "vue";

export default {
  name: "Navbar",
  data() {
    return {};
  },
  methods: {
    /**
     * Page reload
     */
    reloadPage() {
      this.$router.go(this.$router.currentRoute)
    },
    /**
     * Signout
     */
    signout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      Vue.$keycloak.logout({redirectUri: process.env.VUE_APP_REDIRECT_URI});
    },
    openTutorial() {
      window.open('/GWA_Wetland_Pre-Inventory_Tutorial.pdf', '_blank');
    }
  }
}
</script>

<style scoped>
.nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-brand {
  padding-top: 4px;
  padding-bottom: 4px;
}

.router-link-exact-active{
  font-weight: 700;
  color: white!important;
}

.router-link-active{
  font-weight: 700;
  color: white!important;
}
</style>