<template>
  <div>
    <b-overlay :show="deleteAOIOverlay==true" rounded="sm">
      <b-card :id="cardID" @click="cardClicked(cardID)" v-bind:class="{ cardActive: this.cardID == this.selectedAOIItem }" class="mb-2">
        <b-card-title>
          <div class="row pb-0">
            <div class="col col-9 my-auto">
              <span class="">{{cardData.aoi_name}}
                <div v-if="cardData.aoi_description.length > 0" style="display:inline">
                  <b-icon :id="'popover-description-' + cardData.order_id" icon="info-circle" shift-v="0" shift-h="2"></b-icon>
                  <b-popover :target="'popover-description-' + cardData.order_id" triggers="hover" placement="top">
                    <template #title>Description</template>
                    {{cardData.aoi_description}}
                  </b-popover>
                </div>
              </span>
            </div>
            <div class="col col-3 text-right">
              <b-button v-b-tooltip.hover title="Order details" 
                :id="'popover-description-btn-' + cardData.order_id" 
                size="sm" 
                variant="secondary" 
                class="mb-0 mr-1"
                @click="showDownloadModal()"
              >
                <b-icon icon="search"></b-icon>
              </b-button> 
              <b-button v-b-tooltip.hover title="Delete order" size="sm" variant="secondary" class="mb-0 mr-1" @click="deleteOrderClicked(cardData.order_id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </b-card-title>
         <b-card-text class="card-text mb-0">{{cardData.start_date}} - {{cardData.end_date}}</b-card-text>
         <b-badge class="state-badge" :variant='getStateVariantText(cardData.processing_state).variant'>{{getStateVariantText(cardData.processing_state).text}}</b-badge>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";
import axios_services from '@/axios/axios-services.js'

export default {
name: "AOIItem",
  components: {},
  props: {
    cardData: { type: Object, required: true},
    cardID: {type: String},
  },
  data() {
    return {
      deleteAOIOverlay: false,
    };
  },
  computed: {
    selectedAOIItem() {
      return this.$store.getters.getAOIActiveItem;
    },
  },
  methods: {
    /***
     * Card clicked event handler
     */
    cardClicked(id) {
      if (id  === this.selectedAOIItem) {
        eventBus.$emit("refresh-location-aoi-item");
      } else {
        this.$store.commit('setAOIActiveItem', id);
        this.scroll(id);
      }
    },
    /***
     * Event handler for the delete AOI button
     */
    deleteOrderClicked(orderId) {
      this.showDeleteItemModal(orderId);
    },
    /**
     * This function shows the modal modal with some order details
     */
    showDownloadModal() {
      eventBus.$emit("show-modal-order-results", this.cardData)
    },
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content, hideDelay = 4000) {
      this.$parent.$bvToast.toast(content, {
        autoHideDelay: hideDelay,
        title: title,
        variant: variant,
        solid: true
      });
    },
    /***
     * This functions scrolls to the selected card item
     */
    scroll(id) {
      var selector = '#' + String(id);
      const el = this.$el.querySelector(selector);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    /**
     * This function returne the data to display the state badge element
     */
    getStateVariantText(state) {
      if (state === 'queued') {
        return {variant: "secondary", text: "Queued"}
      } else if (state === 'inProgress') {
        return {variant: "primary", text: "In Progress"}
      } else if (state === 'finished') { 
          return {variant: "success", text: "Finished"}
      } else if (state === 'failed') {
          return {variant: "danger", text: "Failed"}
      } else {
        return {variant: "secondary", text: ""}
      }
    },
    /**
     * Modal dialog to confirm the delete operation
     */
    showDeleteItemModal(orderId) {
      this.$parent.$bvModal.msgBoxConfirm('Please confirm that you want to delete the order.', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if (value) {
          this.deleteAOIOverlay = true;
          axios_services
            .delete("orders/".concat(orderId))
            .then(() => {
              this.deleteAOIOverlay = false;
              this.showNotification("success", "Success! Order deleted", "Order successfully deleted");
              eventBus.$emit('clear-map');
              this.$store.dispatch('fetchOrders').then(() => {}); 
              this.$store.commit('setAOIsActiveItem',null);
            })
            .catch((error) => {
              this.deleteAOIOverlay = false;
              console.log("Error: Order could not be deleted: ", error);
              this.showNotification("danger", "Error!", "Order could not be deleted");
          });
        }
      });
    },
  },
  mounted() {},
  beforeDestroy() {},
  created() {},
  watch: {
    selectedAOIItem(v) {
      this.scroll(v);
    }
  },
};
</script>

<style scoped>

.card-title {
  font-size: 1.0em;
  color: #007bff;
  font-weight: 600;
}

.card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-text {
  color: #191919;
  /*font-style: italic;*/
  font-size: 0.85em;
}

.card:hover{
    background-color: #f0f0f0;
    cursor:pointer;
}

.cardActive {
  background-color:#f0f0f0;
}

.label-bold {
  font-weight: bold;
}

.font-weight-validation {
  font-weight: normal;
}

.card-text-aoi {
  color: #191919;
  /*font-style: italic;*/
  font-size: 0.85em;
}
</style>
