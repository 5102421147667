<template>
  <div class="container" style="max-width: 980px">
    <h1 class="pt-5">About</h1>
    <p>
      Orders
      The Wetland Pre-Inventory service has been developed within the ESA-funded
      project
      <a href="https://africa-wetlands.dhigroup.com/index.html" target="_blank"
        >GlobWetland Africa Extension on Wetland Inventory</a
      >
      The service provides information on the occurrence of surface water and
      wetness of soils for a selected region and period of interest. Based on
      the entire archive of freely available Sentinel-1 and Sentinel-2
      high-resolution radar and optical satellite imagery, the service allows to
      report and monitor water and wet soil dynamics over time. The produced
      information products comprise a Wetland pre-Inventory which users can then
      use to create a regional or national wetland inventory.
    </p>

    <p>
      The GlobWetland Africa extension on Wetland Inventory aimed to leverage
      the experience from
      <a href="http://globwetland-africa.org/" target="_blank"
        >GlobWetland Africa</a
      >
      by consolidating the methodological approach on Earth Observation (EO) to
      wetland inventories. The ultimate goal was to provide the Ramsar
      secretariat with practical EO solutions (i.e., technical guidelines and
      software tools) that can support the Ramsar Contracting Parties (i.e.,
      countries signatory to the Ramsar Convention on wetlands) to integrate
      Earth Observation into their national systems and processes on wetland
      inventories and to efficiently report on SDG 6.6.1 on changes in
      water-related ecosystems.
    </p>

    <p>
      The European Space Agency funded the GlobWetland Africa project and the
      extension on wetland inventory in partnership with the Ramsar Convention
      on Wetlands.
    </p>

    <p class="mt-4">
      <b>Important note:</b> The GlobWetland-Africa water and wetness (wet soil)
      mapping service is designed to work with Sentinel-1 GRDH and Sentinel-2
      L1C images, which means the earliest start date is 01.01.2017.
    </p>

    <div class="row pt-5">
      <div class="col-sm align-self-center">
        <div>
          <img src="/img_esa.png" class="img-fluid" />
        </div>
      </div>
      <div class="col-sm align-self-center">
        <img src="/img_ramsar.png" class="img-fluid" />
      </div>
      <div class="col-sm align-self-center">
        <img src="/img_gwa.png" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>
