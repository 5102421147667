<template>
  <div>
    <Navbar />
    <router-view />
    <OrderResultsModal />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import OrderResultsModal from "@/pages/OrderResults/OrderResultsModal.vue"

export default {
  name: "App",
  components: {
    Navbar,
    OrderResultsModal,
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
</style>

