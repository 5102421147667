<template>
  <div>
    <div id="map-with-sidebar-container">
      <div v-bind:class="[sidebarOpened ? 'toggle-icon-opened-position' : 'toggle-icon-closed-position']">
        <b-icon v-if="!sidebarOpened" @click="toggleSidebar"  font-scale="2.0" icon="chevron-right" class="toggle-icon rounded p-1" variant="dark"></b-icon>
        <b-icon v-if="sidebarOpened"  @click="toggleSidebar"  font-scale="2.0" icon="chevron-left" class="toggle-icon rounded p-1" variant="dark"></b-icon>
      </div>
      <div id="sidebar" ref="sidebar" v-bind:class="[sidebarOpened ? 'sidebar-opened' : 'sidebar-closed']">
          <transition name="fade">
            <div v-show="sidebarOpened">
              <SidebarContent></SidebarContent> 
            </div>
          </transition>
      </div>
      <Map :invalidateSize="invalidateSize"/>
    </div>
  </div>
</template>

<script>
import Map from '@/pages/CreateOrder/Map.vue'
import SidebarContent from '@/pages/CreateOrder/SidebarContent.vue'

export default {
  name: "CreateOrder",
  components: {
    SidebarContent,
    Map,
  },
  data() {
    return {
      sidebarOpened: true,
      invalidateSize: null,
    };
  },
  mounted() {
    this.$refs.sidebar.addEventListener("transitionend", this.transitionendHandler);
  },
  beforeDestroy: function() {
    this.$refs.sidebar.removeEventListener("transitionend", this.transitionendHandler);
  },
  methods: {
    /**
     * This function toggles the sidebar container
     */
    toggleSidebar() {
      this.sidebarOpened = !this.sidebarOpened;
    },
    /**
     * This function re-renders the leaflet map (after container resize event)
     */
    transitionendHandler() {
      this.invalidateSize = this.sidebarOpened;
    }
  },
};
</script>

<style scoped>
#map-with-sidebar-container {
  position: absolute;
  height: calc(100% - 60px) !important;
  width: 100% !important;
  overflow-y: hidden !important;
}

#sidebar {
  display: block;
  height: 100%;
  max-width: 100%;
  float: left;
  background-color: white;
  z-index: 1;
  border-right: 1px solid lightgray;
}

/**
 * Sidebar toggle animation (closing)
 */
.sidebar-closed {
  width: 60px !important;
  transition: 0.5s;
}

/**
 * Sidebar toggle animation (opening)
 */
.sidebar-opened {
  width: 500px !important;
  transition:.5s;
} 

/**
 * Animation to display sidebar content with delay (see vue animations)
 */
.fade-enter-active {
  transition: all 0.45s;
  display: none;
}

/**
 * Sidebar open icon
 */
.toggle-icon-opened-position {
  position: absolute;
  top :12px;
  left: 450px;
  transition:.5s;
  z-index: 2;
}

/**
 * Sidebar close icon
 */
.toggle-icon-closed-position {
  position: absolute;
  top:12px;
  left: 12px;
  transition:.5s;
  z-index: 2;
}

/**
 * Sidebar icon hover styling
 */
.toggle-icon:hover{
  cursor: pointer;
  background-color: #f4f4f4;
}
</style>
