<template>
  <div>
    <b-modal
      size="lg"
      ref="modal-order-results"
      title="Order Details"
      no-close-on-backdrop
      id="foo"
    >
      <b-form-group>
        <label class="label-style">AOI Name</label>
        <b-form-input
          v-model="modalData.aoi_name"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label class="label-style">AOI Description</label>
          <b-form-textarea
            v-model="modalData.aoi_description"
            rows="2"
            no-resize
            disabled
          ></b-form-textarea>
      </b-form-group>

      <b-row>
        <b-col>
          <b-form-group>
            <label class="label-style">Start Date</label>
            <b-form-input
              v-model="modalData.start_date"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label class="label-style">End Date</label>
            <b-form-input
              v-model="modalData.end_date"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group>
        <label class="label-style">State</label>
        <div class="mt-n1" v-if="modalData.processing_state != null">
        <b-badge class="state-badge" :variant='getStateVariantText(modalData.processing_state).variant'>{{getStateVariantText(modalData.processing_state).text}}</b-badge>
        </div>
      </b-form-group>
      
      <div>
        <div v-if="modalData.processing_state ===  'failed'">
          <span class="text-danger"><span>{{modalData.error_message}}</span></span>
        </div>
        <div v-else class="mt-3">
          <h5>Results</h5>
          <b-link v-if="modalData.processing_state === 'finished' && modalData.result_link != null"  v-on:click="downloadFile(modalData.result_link)">{{getFileName(modalData)}}
            <b-spinner v-if="showDownloadSpinner" variant="primary" small class="ml-1"></b-spinner>
          </b-link>
          <span v-else-if="modalData.processing_state ===  'inProgress' || modalData.processing_state ===  'queued'" class="text-muted">Results are not yet available</span>
        </div>
      </div>

      <template #modal-footer>
        <b-button variant="primary" class="mr-2" @click="hideModal">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";

export default {
  name: "OrderResultsModal",
  data() {
    return {
      modalData: {},
      showDownloadSpinner: false
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    showModal() {
      this.$nextTick(() => {
        this.$refs['modal-order-results'].show();
      })
    },
    hideModal() {
      this.$nextTick(() => {
        this.$refs['modal-order-results'].hide();
      });
    },
    downloadFile(url) {
      this.showDownloadSpinner = true;
      var ref = this;
      setTimeout(() => {
        ref.showDownloadSpinner = false;  // Note: The timeout is a hacky solution an could probably be improved
      }, 1000);
      window.location.href = url.concat("/download");    
    },
    getStateVariantText(state) {
      if (state === 'queued') {
        return {variant: "secondary", text: "Queued"}
      } else if (state === 'inProgress') {
        return {variant: "primary", text: "In Progress"}
      } else if (state === 'finished') { 
          return {variant: "success", text: "Finished"}
      } else if (state === 'failed') {
          return {variant: "danger", text: "Failed"}
      } else {
        return null;
      }
    },
    getFileName(modalData) {
      return "".concat(modalData.aoi_name, '_', modalData.start_date, '_', modalData.end_date);
    }
  },
  created: function () {
    var ref = this;
    eventBus.$on("show-modal-order-results", (data) => {
      this.modalData = data;
      if (this.modalData.aoi_description == null) {this.modalData.aoi_description = ''}   // str.length should not be null
      ref.showModal();
    });
  },
};
</script>

<style>
.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}
</style>
